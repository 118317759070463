import { ChangeDetectionStrategy, Component, EventEmitter, Inject, inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ILocalSubmitters } from '../interfaces/submitter';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Json2Pipe } from 'src/app/shared/json2.pipe';
import { AbstractControl, FormBuilder, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ILocalFormConfig } from '../interfaces/localFormConfig';
import { ILocalProviders } from '../interfaces/localProviders';
import { ILocalInsurances } from '../interfaces/localInsurances';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-submitter',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitterComponent {
  @Input() engLang: boolean;
  @Input() prov: string = '';
  @Input() ins: string = '';
  @Input() subs: ILocalSubmitters[];
  @Input() sub: ILocalSubmitters[];
  @Input() fcs: ILocalFormConfig[];
  @Input() provs: ILocalProviders[];
  @Input() inss: ILocalInsurances[];

  @Output() closeSubmitterDlg: EventEmitter<any> = new EventEmitter();

  readonly dialog = inject(MatDialog);

  ngOnInit() {

    const dialogRef = this.dialog.open(SubmitterDialog, {
      data: {
        engLang: this.engLang,
        prov: this.prov,
        ins: this.ins,
        subs: this.subs,
        sub: this.sub,
        fcs: this.fcs,
        provs: this.provs,
        inss: this.inss
      },
      disableClose: true,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Submitter Dialog result: ${result}`);
      if (result) {
        // send json of submitter data in next statement as oposed to '' when closing
      }
      this.closeSubmitterDlg.emit(result);
    });
  }
}

export function isaIdQualifierValidator(control: AbstractControl): ValidationErrors | null {
  const validValues = ['01', '14', '20', '27', '28', '29', '30', '33', 'ZZ'];
  const value = control.value;

  // Check if the value is in the validValues array
  if (validValues.includes(value)) {
    return null;  // Return null if the value is valid (no error)
  }

  return { invalidIsaQualifier: true, validValues: validValues.join(', ') };  // Return error if the value is not valid
}

@Component({
  selector: 'submitter-dialog',
  templateUrl: './submitter.dialog.html',
  styleUrls: ['./submitter.dialog.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    Json2Pipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitterDialog {

  subIndx: number = -1;  // Selected subs[] index
  provID: string = '';
  insID: string = '';

  form = this._fb.group({
    SubID: [this.data.sub?.SubID,
    [Validators.required]
    ],
    SubVia: [this.data.sub?.SubVia, [
      Validators.required,
      Validators.maxLength(35)
    ]
    ],
    SubLastNm: [this.data.sub?.SubLastNm, [
      Validators.required,
      Validators.maxLength(35)]
    ],
    SubFstNm: [this.data.sub?.SubFstNm,
    [Validators.maxLength(25)]
    ],
    SubIDrem: [this.data.sub?.SubIDrem, [
      Validators.required,
      Validators.maxLength(15)]
    ],
    SubIsa05: [this.data.sub?.SubIsa05, [
      Validators.required,
      isaIdQualifierValidator]
    ],
    SubIsa08: [this.data.sub?.SubIsa08, [
      Validators.required,
      Validators.maxLength(15)]
    ],
    SubIsa07: [this.data.sub?.SubIsa07, [
      Validators.required,
      isaIdQualifierValidator]
    ],
    SubGs02: [this.data.sub?.SubGs02, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(15)]
    ],
    SubGs03: [this.data.sub?.SubGs03, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(15)]
    ],
    SubContact: [this.data.sub?.SubContact, [
      Validators.required,
      Validators.maxLength(34)]
    ],
    SubContTel: [this.data.sub?.SubContTel, [
      Validators.required, Validators.pattern(/\d{10}/g)]
    ],
    SubContFax: [this.data.sub?.SubContFax,
    [Validators.pattern(/\d{10}/g)]
    ],
    SubContEmail: [this.data.sub?.SubContEmail, [
      Validators.maxLength(50),
      Validators.email]
    ],
    SubUser: [this.data.sub?.SubUser,
    [Validators.required,
    Validators.maxLength(20)]
    ],
    SubPw: [this.data.sub?.SubPw,
    [Validators.required,
    Validators.maxLength(20)]
    ],
    provID: [''],
    insID: ['']
  }, { updateOn: 'blur' });

  get SubID() {
    return this.form.get('SubID');
  }

  get SubVia() {
    return this.form.get('SubVia');
  }

  get SubIsa05() {
    return this.form.get('SubIsa05');
  }

  get SubIsa07() {
    return this.form.get('SubIsa07');
  }

  get SubIsa08() {
    return this.form.get('SubIsa08');
  }

  get SubGs02() {
    return this.form.get('SubGs02');
  }

  get SubGs03() {
    return this.form.get('SubGs03');
  }

  get SubContTel() {
    return this.form.get('SubContTel');
  }

  get SubContFax() {
    return this.form.get('SubContFax');
  }

  get SubUser() {
    return this.form.get('SubUser');
  }

  get SubPw() {
    return this.form.get('SubPw');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
      {
        engLang: boolean,
        prov: string,
        ins: string,
        subs: ILocalSubmitters[],
        sub: ILocalSubmitters,
        fcs: ILocalFormConfig[],
        provs: ILocalProviders[],
        inss: ILocalInsurances[]
      },
    private _fb: FormBuilder,
    private _dlgRef: MatDialogRef<SubmitterDialog>
  ) {
    this.form.markAllAsTouched();  // Marks all controls as touched to show validation messages
    this.form.updateValueAndValidity(); // Ensures the form updates its status and validators
  }

  onClick_save() {
    const result = { form: this.form.value, action: 'save' };
    this._dlgRef.close(result);
  }

  onClick_delete() {
    const result = { form: this.form.value, action: 'delete' };
    this._dlgRef.close(result);
  }

  onChange_subID(event) {
    const i = this.data.subs.findIndex(e => e.SubID === event.value);
    this.form.controls.SubVia.setValue(this.data.subs[i].SubVia);
    this.form.controls.SubLastNm.setValue(this.data.subs[i].SubLastNm);
    this.form.controls.SubFstNm.setValue(this.data.subs[i].SubFstNm);
    this.form.controls.SubIDrem.setValue(this.data.subs[i].SubIDrem);
    this.form.controls.SubIsa05.setValue(this.data.subs[i].SubIsa05);
    this.form.controls.SubIDrem.setValue(this.data.subs[i].SubIDrem);
    this.form.controls.SubIsa07.setValue(this.data.subs[i].SubIsa07);
    this.form.controls.SubIsa08.setValue(this.data.subs[i].SubIsa08);
    this.form.controls.SubGs02.setValue(this.data.subs[i].SubGs02);
    this.form.controls.SubGs03.setValue(this.data.subs[i].SubGs03);
    this.form.controls.SubContact.setValue(this.data.subs[i].SubContact);
    this.form.controls.SubContTel.setValue(this.data.subs[i].SubContTel);
    this.form.controls.SubContFax.setValue(this.data.subs[i].SubContFax);
    this.form.controls.SubContEmail.setValue(this.data.subs[i].SubContEmail);
    this.form.controls.SubUser.setValue(this.data.subs[i].SubUser);
    this.form.controls.SubPw.setValue(this.data.subs[i].SubPw);
    this.subIndx = i;

    const fc = this.data.fcs.find(f => f.subID === event.value);
    if (fc) {
      const prov = this.data.provs.find(p => p.pKey === fc.provID);
      if (!this.data.prov) {  // If no prov input
        if (prov) {
          this.data.prov = '(' + prov.pKey + ') ' + prov.alias;
          this.provID = prov.pKey
        }
      }

      const ins = this.data.inss.find(i => i.pKey === fc.insID);
      if (!this.data.ins) {
        if (ins) {
          this.data.ins = '(' + ins.pKey + ') ' + ins.alias;
          this.insID = ins.pKey;
        }
      }
    }
    this.provID = this.data.prov?.replace(/\D*/g, '');
    this.form.controls.provID.setValue(this.provID);
    this.insID = this.data.ins?.replace(/\D*/g, '');
    this.form.controls.insID.setValue(this.insID);
  }
}
